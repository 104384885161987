import { VariantProps } from '@stitches/react';
import { CSS, styled, pxToRem, Button } from '@genialcare/atipico-react';

const defaultBackgroundStyle: CSS = {
  backgroundColor: '$neutral0',
  '&:hover': {
    backgroundColor: '$neutral100',
  },
};

export const Root = styled(Button, {
  minWidth: 'auto',
  width: pxToRem(200),
  height: pxToRem(48),
  fontSize: pxToRem(16),
  fontWeight: '$bold',
  variants: {
    colorScheme: {
      default: {
        ...defaultBackgroundStyle,
        color: '$purple500',
      },
      red: {
        ...defaultBackgroundStyle,
        color: '$red500',
      },
    },
  },
  defaultVariants: {
    colorScheme: 'default',
  },
});

export type ButtonVariants = VariantProps<typeof Root>;
