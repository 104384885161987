export enum TaskStatus {
  Open = 'OPEN',
  Resolved = 'RESOLVED',
  Overdue = 'OVERDUE',
  ToBeOverdue = 'TO_BE_OVERDUE',
}

export enum TaskContextType {
  ClinicalGuidance = 'CLINICAL_GUIDANCE',
  Registry = 'REGISTRY',
  ClinicalCase = 'CLINICAL_CASE',
  User = 'USER',
}

export enum TaskContextTypeTranslation {
  'CLINICAL_GUIDANCE' = 'Comunicações',
  'REGISTRY' = 'Orientação Clínica',
  'CLINICAL_CASE' = 'Caso',
  'USER' = 'Pessoais',
}

export enum TaskContextTypeDescription {
  'CLINICAL_GUIDANCE' = 'Tarefa de comunicação',
  'REGISTRY' = 'Tarefa de Orientação clínica',
  'CLINICAL_CASE' = 'Tarefa do caso',
  'USER' = 'Tarefa pessoal',
}

export const TaskContextTypeOptions = [
  { value: '', children: 'Todas' },
  ...Object.keys(TaskContextTypeTranslation).map((type) => ({
    value: type,
    children: TaskContextTypeTranslation[type as keyof typeof TaskContextTypeTranslation],
  })),
];

export type TaskFiltersType = {
  status?: TaskStatus | null;
  clinicalCaseId?: string | null;
  contextType?: TaskContextType | null;
};

export type TaskParticipant = {
  id: string;
  name: string;
};

export type TaskDetails = {
  title?: string;
};

export type TaskContext = {
  id: string;
  type: TaskContextType;
  clinicalCase?: {
    id: string;
    name: string;
  };
  details?: TaskDetails;
};

export type Task = {
  id: string;
  title: string;
  description?: string;
  status: TaskStatus;
  context: TaskContext;
  assignee: TaskParticipant;
  createdBy: TaskParticipant;
  dueDate?: string;
  createdAt: string;
  updatedAt: string;
};

export type TotalTasks = {
  total: number;
  totalOpen: number;
  totalToBeOverdue: number;
  totalOverdue: number;
  totalResolved: number;
};

export type PersonalTask = Omit<Task, 'context'>;

export type CreateTaskRequest = {
  title: string;
  description?: string;
  dueDate?: string;
  assignee: Pick<TaskParticipant, 'id'>;
  context: {
    id?: string;
    type?: TaskContextType;
    clinicalCase?: {
      id: string;
    };
  };
};

export type CreatePersonalTaskRequest = {
  title: string;
  description?: string;
  dueDate?: string;
};

export type UpdateTaskRequest = {
  title?: string;
  description?: string;
  dueDate?: string;
  status?: TaskStatus;
  assignee?: Pick<TaskParticipant, 'id'>;
};
