import { gql } from '@apollo/client';

export const GET_USER_TASKS_SUM = gql`
  query getUserTasksSum($clinicalCaseId: ID, $contextType: ContextType) {
    user {
      id
      __typename
      totalTasks(clinicalCaseId: $clinicalCaseId, contextType: $contextType) {
        total
        totalOpen
        totalToBeOverdue
        totalOverdue
        totalResolved
      }
    }
  }
`;
