import { ClinicalCase } from 'types/clinicalCase';
import { Task } from 'types/task';
import { Session } from 'types/session';

export enum RegistryStatus {
  Open = 'OPEN',
  Finished = 'FINISHED',
}

export enum SubjectStatus {
  Open = 'OPEN',
  Done = 'DONE',
}

export type Subject = {
  id: string;
  text: string;
  status: SubjectStatus;
  createdAt: string;
  createdBy: RegistryUser;
  updatedAt: string;
  updatedBy?: RegistryUser;
  clinicalDecision?: SubjectClinicalDecision;
  attachments?: Attachment[];
};

export type Attachment = {
  key: string;
  filename: string;
  contentType: string;
  checksum: string;
  serviceName: string;
  byteSize: number;
  url: string;
  createdAt: string;
};

export type RegistryAttachment = Attachment;
export type SubjectAttachment = Attachment;

export type SubjectClinicalDecision = {
  id: string;
  text: string;
  createdAt: string;
  updatedAt: string;
  createdBy: RegistryUser;
  updatedBy?: RegistryUser;
};

export type RegistryUser = {
  id: string;
  name?: string;
};

export type ObservableLightFormRegistration = {
  id: string;
};

export type Registry = {
  id: string;
  content?: string;
  status: RegistryStatus;
  statusDescription: string;
  createdAt: string;
  createdBy: RegistryUser;
  updatedAt: string;
  updatedBy?: RegistryUser;
  subjects: Subject[];
  clinicalCase: Partial<ClinicalCase>;
  tasks?: Task[];
  session?: Session;
  attachments?: Attachment[];
  observableLightRegistrations?: ObservableLightFormRegistration[];
};
