import { styled, pxToRem, Flex, Typography } from '@genialcare/atipico-react';

export const HeaderRoot = styled(Flex, {
  position: 'fixed',
  width: '100%',
  top: 0,
  gap: pxToRem(24),
  padding: `${pxToRem(8)} ${pxToRem(24)}`,
  backgroundColor: '$neutral0',
  zIndex: 2,
  '@lg': {
    gap: pxToRem(40),
    padding: `${pxToRem(16)} ${pxToRem(48)}`,
  },
  variants: {
    shadow: {
      true: {
        boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.08)',
      },
    },
  },
});

export const UserContent = styled(Flex, {
  gap: pxToRem(12),
});

export const UserInfo = styled('div', {
  display: 'none',
  flexDirection: 'column',
  alignItems: 'flex-end',
  '@lg': {
    display: 'flex',
  },
});

export const UserName = styled(Typography, {
  margin: 0,
  padding: 0,
  fontFamily: '$dm-sans, sans-serif',
});

export const UserEmail = styled(Typography, {
  margin: 0,
  padding: 0,
  color: '$neutral400',
});
