import { gql } from '@apollo/client';

export const COMPLETE_PERFORMANCE_REGISTRY = gql`
  mutation completePerformanceRegistry($clinicalCaseId: ID!, $sessionId: ID!) {
    completePerformanceRegistry(clinicalCaseId: $clinicalCaseId, sessionId: $sessionId) {
      sessionId
    }
  }
`;

export const COMPLETE_PERFORMANCE_REGISTRY_WITH_MEASUREMENTS = gql`
  mutation completePerformanceRegistry(
    $clinicalCaseId: ID!
    $sessionId: ID!
    $request: PerformanceRegistryInput
  ) {
    completePerformanceRegistry(
      clinicalCaseId: $clinicalCaseId
      sessionId: $sessionId
      request: $request
    ) {
      sessionId
      completedAt
      completedById
      measurements {
        id
        customTargetListId
        registerType
        data {
          ... on AnecdotalMeasurementData {
            note
          }
          ... on OccurrenceMeasurementData {
            occurred
          }
          ... on FrequencyMeasurementData {
            frequencyCount
          }
          ... on TrialMeasurementData {
            promptId
            observation
          }
          ... on TaskAnalysisMeasurementData {
            steps {
              stepId
            }
          }
          ... on DurationMeasurementData {
            duration
          }
        }
      }
    }
  }
`;
