import { graphql } from 'msw';
import { v4 as uuidV4 } from 'uuid';

export default graphql.query('getUserClinicalCaseAvailableHours', (_req, res, ctx) => {
  return res(
    ctx.delay(1500),
    ctx.data({
      user: {
        __typename: 'User',
        clinicalCaseAvailableHours: [
          {
            __typename: 'ClinicalCaseAvailableHours',
            allocatedInClinicalCase: true,
            preApprovedFamily: true,
            availableHourChild: {
              id: uuidV4(),
            },
            location: {
              __typename: 'Location',
              id: uuidV4(),
              category: 'clinic',
              name: 'Casa Genial - Morumbi',
            },
            availableHours: [
              {
                __typename: 'AvailableHourPerWeek',
                weekday: 'monday',
                hours: [
                  {
                    __typename: 'AvailableHour',
                    id: uuidV4(),
                    startAt: '0000-01-01T08:00:00Z',
                    endAt: '0000-01-01T09:00:00Z',
                    preApprovedFamily: true,
                  },
                  {
                    __typename: 'AvailableHour',
                    id: uuidV4(),
                    startAt: '0000-01-01T08:30:00Z',
                    endAt: '0000-01-01T09:30:00Z',
                    preApprovedFamily: true,
                  },
                  {
                    __typename: 'AvailableHour',
                    id: uuidV4(),
                    startAt: '0000-01-01T09:00:00Z',
                    endAt: '0000-01-01T10:00:00Z',
                    preApprovedFamily: false,
                  },
                  {
                    __typename: 'AvailableHour',
                    id: uuidV4(),
                    startAt: '0000-01-01T08:00:00Z',
                    endAt: '0000-01-01T09:00:00Z',
                    preApprovedFamily: false,
                  },
                  {
                    __typename: 'AvailableHour',
                    id: uuidV4(),
                    startAt: '0000-01-01T08:30:00Z',
                    endAt: '0000-01-01T09:30:00Z',
                    preApprovedFamily: false,
                  },
                  {
                    __typename: 'AvailableHour',
                    id: uuidV4(),
                    startAt: '0000-01-01T09:00:00Z',
                    endAt: '0000-01-01T10:00:00Z',
                    preApprovedFamily: false,
                  },
                  {
                    __typename: 'AvailableHour',
                    id: uuidV4(),
                    startAt: '0000-01-01T08:00:00Z',
                    endAt: '0000-01-01T09:00:00Z',
                    preApprovedFamily: false,
                  },
                  {
                    __typename: 'AvailableHour',
                    id: uuidV4(),
                    startAt: '0000-01-01T08:30:00Z',
                    endAt: '0000-01-01T09:30:00Z',
                    preApprovedFamily: false,
                  },
                  {
                    __typename: 'AvailableHour',
                    id: uuidV4(),
                    startAt: '0000-01-01T09:00:00Z',
                    endAt: '0000-01-01T10:00:00Z',
                    preApprovedFamily: false,
                  },
                  {
                    __typename: 'AvailableHour',
                    id: uuidV4(),
                    startAt: '0000-01-01T08:00:00Z',
                    endAt: '0000-01-01T09:00:00Z',
                    preApprovedFamily: false,
                  },
                  {
                    __typename: 'AvailableHour',
                    id: uuidV4(),
                    startAt: '0000-01-01T08:30:00Z',
                    endAt: '0000-01-01T09:30:00Z',
                    preApprovedFamily: false,
                  },
                  {
                    __typename: 'AvailableHour',
                    id: uuidV4(),
                    startAt: '0000-01-01T09:00:00Z',
                    endAt: '0000-01-01T10:00:00Z',
                    preApprovedFamily: false,
                  },
                  {
                    __typename: 'AvailableHour',
                    id: uuidV4(),
                    startAt: '0000-01-01T08:00:00Z',
                    endAt: '0000-01-01T09:00:00Z',
                    preApprovedFamily: false,
                  },
                  {
                    __typename: 'AvailableHour',
                    id: uuidV4(),
                    startAt: '0000-01-01T08:30:00Z',
                    endAt: '0000-01-01T09:30:00Z',
                    preApprovedFamily: false,
                  },
                  {
                    __typename: 'AvailableHour',
                    id: uuidV4(),
                    startAt: '0000-01-01T09:00:00Z',
                    endAt: '0000-01-01T10:00:00Z',
                    preApprovedFamily: false,
                  },
                ],
              },
              {
                __typename: 'AvailableHourPerWeek',
                weekday: 'tuesday',
                hours: [
                  {
                    __typename: 'AvailableHour',
                    id: uuidV4(),
                    startAt: '0000-01-01T08:00:00Z',
                    endAt: '0000-01-01T09:00:00Z',
                    preApprovedFamily: false,
                  },
                  {
                    __typename: 'AvailableHour',
                    id: uuidV4(),
                    startAt: '0000-01-01T09:00:00Z',
                    endAt: '0000-01-01T10:00:00Z',
                    preApprovedFamily: false,
                  },
                ],
              },
            ],
          },
          {
            __typename: 'ClinicalCaseAvailableHours',
            allocatedInClinicalCase: false,
            preApprovedFamily: false,
            availableHourChild: {
              id: uuidV4(),
            },
            location: {
              __typename: 'Location',
              id: uuidV4(),
              category: 'clinic',
              name: 'Casa Genial - Tatuapé',
            },
            availableHours: [
              {
                __typename: 'AvailableHourPerWeek',
                weekday: 'monday',
                hours: [
                  {
                    __typename: 'AvailableHour',
                    id: uuidV4(),
                    startAt: '0000-01-01T08:00:00Z',
                    endAt: '0000-01-01T09:00:00Z',
                    preApprovedFamily: false,
                  },
                  {
                    __typename: 'AvailableHour',
                    id: uuidV4(),
                    startAt: '0000-01-01T08:30:00Z',
                    endAt: '0000-01-01T09:30:00Z',
                    preApprovedFamily: false,
                  },
                  {
                    __typename: 'AvailableHour',
                    id: uuidV4(),
                    startAt: '0000-01-01T09:00:00Z',
                    endAt: '0000-01-01T10:00:00Z',
                    preApprovedFamily: false,
                  },
                  {
                    __typename: 'AvailableHour',
                    id: uuidV4(),
                    startAt: '0000-01-01T10:00:00Z',
                    endAt: '0000-01-01T11:00:00Z',
                    preApprovedFamily: false,
                  },
                ],
              },
              {
                __typename: 'AvailableHourPerWeek',
                weekday: 'tuesday',
                hours: [
                  {
                    __typename: 'AvailableHour',
                    id: uuidV4(),
                    startAt: '0000-01-01T08:00:00Z',
                    endAt: '0000-01-01T09:00:00Z',
                    preApprovedFamily: false,
                  },
                  {
                    __typename: 'AvailableHour',
                    id: uuidV4(),
                    startAt: '0000-01-01T08:30:00Z',
                    endAt: '0000-01-01T09:30:00Z',
                    preApprovedFamily: false,
                  },
                  {
                    __typename: 'AvailableHour',
                    id: uuidV4(),
                    startAt: '0000-01-01T09:00:00Z',
                    endAt: '0000-01-01T10:00:00Z',
                    preApprovedFamily: false,
                  },
                ],
              },
              {
                __typename: 'AvailableHourPerWeek',
                weekday: 'friday',
                hours: [
                  {
                    __typename: 'AvailableHour',
                    id: uuidV4(),
                    startAt: '0000-01-01T08:00:00Z',
                    endAt: '0000-01-01T09:00:00Z',
                    preApprovedFamily: false,
                  },
                  {
                    __typename: 'AvailableHour',
                    id: uuidV4(),
                    startAt: '0000-01-01T08:30:00Z',
                    endAt: '0000-01-01T09:30:00Z',
                    preApprovedFamily: false,
                  },
                  {
                    __typename: 'AvailableHour',
                    id: uuidV4(),
                    startAt: '0000-01-01T09:00:00Z',
                    endAt: '0000-01-01T10:00:00Z',
                    preApprovedFamily: false,
                  },
                ],
              },
            ],
          },
        ],
      },
    }),
  );
});
