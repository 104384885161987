import { gql } from '@apollo/client';
import { PENDENCY_FRAGMENT_WITH_CONTEXT_DETAIL } from './pendencies/fragments/pendencyFragment';
import { PLANNING_FRAGMENT } from './planning';

export const GET_HOME_PAGE_DATA = gql`
  ${PENDENCY_FRAGMENT_WITH_CONTEXT_DETAIL}
  ${PLANNING_FRAGMENT}

  query getHomePageData(
    $todaySessionsFilter: SessionInputFilters
    $nextSessionsFilter: SessionInputFilters
    $tasksCardStatusFilter: TaskStatus
    $orderBy: SessionOrderByInput
  ) {
    user {
      id
      firstName
      clinicalCases {
        id
        name
      }
      sessionsCard: sessions(filters: $todaySessionsFilter, orderBy: $orderBy) {
        id
        finalStartedAt
      }
      sessionsList: sessions(filters: $nextSessionsFilter, orderBy: $orderBy) {
        id
        status
        finalStartedAt
        clinicalCaseId
        clinicalCase {
          name
        }
        sessionType
        sessionTypeDescription
        discipline
      }
      tasksCard: tasks(status: $tasksCardStatusFilter) {
        id
        status
        title
        dueDate
        context {
          id
          type
          clinicalCase {
            id
            name
          }
        }
      }
      pendencies {
        ...PendencyFieldsWithContextDetail
        __typename
      }
      plannings {
        ...PlanningFields
        __typename
      }
    }
  }
`;
