import React, { useEffect } from 'react';
import { NotificationsContainer } from 'components/NotificationsContainer';
import { usePushNotifications } from 'hooks/usePushNotifications';
import { Avatar } from '../Avatar';
import * as S from './Header.styles';

type HeaderProps = {
  shadow: boolean;
  user: {
    name: string;
    email: string;
    image: string;
  };
  partnerInfo: PartnerProps | undefined;
};

export type PartnerProps = {
  legalName: string;
};

export const Header: React.FC<HeaderProps> = ({ user, partnerInfo, shadow }) => {
  const { name, email, image } = user;
  const { enableNotifications } = usePushNotifications();

  useEffect(() => {
    enableNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <S.HeaderRoot as="header" align="center" justify="end" shadow={shadow}>
      <NotificationsContainer />

      <S.UserContent align="center">
        <S.UserInfo>
          <S.UserName variant="body1" color="purple500" weight="bold">
            {name}
          </S.UserName>
          <S.UserEmail variant="subtitle">
            {partnerInfo ? partnerInfo.legalName : email}
          </S.UserEmail>
        </S.UserInfo>
        <Avatar
          css={{ zIndex: 2 }}
          size={{
            '@initial': 'sm',
            '@lg': 'md',
          }}
          alt={name}
          src={image}
        />
      </S.UserContent>
    </S.HeaderRoot>
  );
};
