import { gql } from '@apollo/client';
import { TASK_FRAGMENT } from '../task/fragments/taskFragment';

export const GET_USER_TASKS = gql`
  ${TASK_FRAGMENT}

  query getUserTasks(
    $clinicalCaseId: ID
    $contextType: ContextType
    $status: TaskStatus
    $limit: Int
    $offset: Int
  ) {
    user {
      id
      tasks(
        clinicalCaseId: $clinicalCaseId
        contextType: $contextType
        status: $status
        limit: $limit
        offset: $offset
      ) {
        ...TaskFields
        __typename
      }
    }
  }
`;
