import React from 'react';
import {
  USER_PENDENCIES_PAGE,
  USER_SESSIONS_PAGE,
  USER_TASKS_PAGE,
  MARKETPLACE_PAGE,
  PLANNING_BY_CLINICAL_CASE_PAGE,
  FAMILY_SUPPORT_CENTER_LINK,
} from 'constants/flags';
import { useFeatureFlag } from 'hooks/useFeatureFlag';
import { MenuItem } from './MenuItem';
import * as S from './MenuContent.styles';
import { useUserData } from 'contexts/user';

export const MenuContent = ({ isOpen = false }: { isOpen: boolean }) => {
  const { email } = useUserData();

  const userPendenciesEnabled = useFeatureFlag(USER_PENDENCIES_PAGE);
  const userSessionsEnabled = useFeatureFlag(USER_SESSIONS_PAGE);
  const userTasksEnabled = useFeatureFlag(USER_TASKS_PAGE);
  const marketplaceEnabled = useFeatureFlag(MARKETPLACE_PAGE);
  const familySupportCenterEnabled = useFeatureFlag(FAMILY_SUPPORT_CENTER_LINK, {
    attributes: {
      user_email: email,
    },
  });
  const planningByClinicalCaseEnabled = useFeatureFlag(PLANNING_BY_CLINICAL_CASE_PAGE, {
    attributes: {
      user_email: email,
    },
  });

  return (
    <S.List as="ol" isOpen={isOpen} data-testid="side-menu-items" data-helphero="side-menu-items">
      <MenuItem to="/panel/home" Icon={S.IconHome} text="Início" isOpen={isOpen} />
      {!planningByClinicalCaseEnabled && (
        <MenuItem
          to="/panel/users/session-planning"
          Icon={S.IconStatusBookOpen}
          text="Planejamento"
          isOpen={isOpen}
          data-testid="session-planning-link"
        />
      )}
      {planningByClinicalCaseEnabled && (
        <MenuItem
          to="/panel/users/planning"
          Icon={S.IconStatusBookOpen}
          text="Planejamento"
          isOpen={isOpen}
          data-testid="clinical-case-planning-link"
        />
      )}
      {userPendenciesEnabled && (
        <MenuItem
          to="/panel/users/pendencies"
          Icon={S.IconPendencies}
          text="Minhas pendências"
          isOpen={isOpen}
          data-testid="user-pendencies-link"
        />
      )}
      {userSessionsEnabled && (
        <MenuItem
          to="/panel/users/sessions"
          Icon={S.IconCalendar}
          text="Minhas sessões"
          isOpen={isOpen}
          data-testid="user-sessions-link"
        />
      )}
      {userTasksEnabled && (
        <MenuItem
          to="/panel/users/tasks"
          Icon={S.IconsTasks}
          text="Minhas tarefas"
          isOpen={isOpen}
          data-testid="user-tasks-link"
        />
      )}
      {familySupportCenterEnabled && (
        <MenuItem
          to="/panel/users/family-support-center"
          Icon={S.IconStatusHeart}
          text="Genial Junto"
          isOpen={isOpen}
          data-testid="family-support-center-link"
        />
      )}
      {marketplaceEnabled && (
        <MenuItem
          showBadge
          to="/panel/users/marketplace"
          Icon={S.IconHours}
          text="Minhas horas"
          isOpen={isOpen}
          data-testid="user-marketplace-link"
        />
      )}
      <MenuItem to="/panel/settings" Icon={S.IconSettings} text="Configurações" isOpen={isOpen} />
    </S.List>
  );
};
