export enum ItemScoresStatuses {
  Selected = 'SELECTED',
}

export type VinelandProtocolItem = {
  id: string;
  code: number;
  domain: string;
  areaCode?: string;
  areaName?: string;
  subdomain: string;
  description: string;
};

export type VinelandScoreItem = {
  id: string;
  score: string;
  filledAutomatically: boolean;
  protocolItem: VinelandProtocolItem;
  status?: ItemScoresStatuses;
};

export type VinelandReport = {
  id: string;
  interviewRespondentName: string;
  assessedAt: string;
  peiSuggestions: {
    suggestions?: {
      subdomain?: string;
      reason?: string;
    }[];
  };
};

export type VinelandSubdomainScore = {
  id: string;
  subdomain: string;
  ageEquivalent: string;
  vScaleScore: number;
  gsv: number;
};

export type VinelandDomainScore = {
  id: string;
  domain: string;
  standardScore: number;
  subdomainScores: VinelandSubdomainScore[];
};
