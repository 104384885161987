import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { DocumentNode } from 'graphql';
import { TypedDocumentNode } from '@graphql-typed-document-node/core';
import { useLazyQuery, LazyQueryHookOptions } from '@apollo/client';
import { logError } from 'utils/logger';

type ExecutionOptions<T> = {
  executeQueryIf: (variables?: T) => boolean;
};

export const useAuthorizedQuery = <TData, TVariables>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: LazyQueryHookOptions<TData, TVariables>,
  executionOptions: ExecutionOptions<LazyQueryHookOptions<TData, TVariables>> = {
    executeQueryIf: () => true,
  },
) => {
  const { getAccessTokenSilently } = useAuth0();
  const [requestQuery, { loading, error, data, ...otherQueryMethods }] = useLazyQuery(
    query,
    options,
  );
  const { executeQueryIf } = executionOptions;

  useEffect(() => {
    const authorizedQuery = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        requestQuery({
          context: {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          },
        });
      } catch (e) {
        if ((e as Error).message !== 'Login required') {
          logError(e as Error);
        }
      }
    };

    executeQueryIf(options) && authorizedQuery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    loading,
    error,
    data,
    ...otherQueryMethods,
  };
};

export default useAuthorizedQuery;
