import { gql } from '@apollo/client';

export const GET_CLINICAL_CASE_FILES_AND_SUPPORT_LINKS = gql`
  query getClinicalCaseSupportLinks($id: ID!) {
    clinicalCase(id: $id) {
      id
      supportLinks {
        id
        category
        url
        createdBy {
          id
          name
        }
        createdAt
      }
      files {
        id
        name
        contentType
        attachment {
          url
        }
        createdBy {
          id
          email
        }
        createdAt
      }
    }
  }
`;
