import React, { PropsWithChildren, useEffect, useState } from 'react';
import { Header } from '../Header';
import { useUserData } from '../../contexts/user';
import { useAuthenticatedUser } from '../../contexts/authenticatedUser';
import { SideMenu } from 'components/SideMenu';
import * as S from './Layout.styles';
import { Loading } from 'components/Loading';
import { Banner } from 'components/Banner';
import { Toast } from 'components/Toast';
import { EeselWidget } from 'components/EeselWidget';
import { FileCloudOff } from '@genialcare/atipico-react';
import { useNetworkAvailability } from 'contexts/networkAvailability';
import { useFeatureFlag } from 'hooks/useFeatureFlag';
import { EESEL_WIDGET } from 'constants/flags';
import { useLocation } from 'react-router-dom';

export type LayoutProps = PropsWithChildren & {
  loading?: boolean;
};

const excludedEeselWidgetPaths = (pathname: string) => {
  const pattern = /performance-measurement|sessions\/.*\/details/i;
  return pattern.test(pathname);
};

export const Layout: React.FC<LayoutProps> = ({ loading = false, children }) => {
  const [showOfflineError, setShowOfflineError] = useState(false);
  const { isOffline } = useNetworkAvailability();
  const eeselWidgetEnabled = useFeatureFlag(EESEL_WIDGET);
  const user = useUserData();
  const authenticatedUser = useAuthenticatedUser();
  const { pathname } = useLocation();
  const addEeselWidget = eeselWidgetEnabled && !excludedEeselWidgetPaths(pathname);

  useEffect(() => {
    const eeselWidget = document.getElementById('eesel-oracle-widget') as HTMLElement;
    if (eeselWidget && !addEeselWidget) {
      document.body.removeChild(eeselWidget);
      window.EeselAdded = false;
    }
  }, [addEeselWidget]);

  useEffect(() => {
    if (isOffline) setShowOfflineError(true);

    const timeout = setTimeout(() => {
      setShowOfflineError(false);
    }, 3000);

    return () => clearTimeout(timeout);
  }, [isOffline]);

  return (
    <S.Root>
      <SideMenu />
      <Header user={user} partnerInfo={authenticatedUser.partnerInfo} shadow />
      {loading ? <Loading /> : children}

      <Toast open={showOfflineError}>
        <Banner
          type="error"
          Icon={FileCloudOff}
          closable
          handleClose={() => setShowOfflineError(false)}
        >
          Parece que você está sem conexão à internet. Lembre-se de conectar novamente sua internet
          antes de enviar dados.
        </Banner>
      </Toast>
      {addEeselWidget && <EeselWidget />}
    </S.Root>
  );
};
