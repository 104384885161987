import { Option } from 'components/Form/Select';

export const addPhoneMask = (phoneNumber: string | boolean) => {
  if (typeof phoneNumber !== 'string' || phoneNumber.length === 0) return '-';

  const onlyNumber = phoneNumber.replace(/\D/g, '');

  if (phoneNumber?.startsWith('55')) {
    return onlyNumber.replace(/(\d{2})(\d{2})(\d{4,5})(\d{4})$/, '+$1 ($2) $3-$4');
  }

  if (phoneNumber.length > 9) {
    return onlyNumber.replace(/(\d{2})(\d{4,5})(\d{4})$/, '($1) $2-$3');
  }

  return onlyNumber.replace(/(\d{4,5})(\d{4})$/, '$1-$2');
};

export const parseBoolean = (value: string): boolean => value === 'true';

export function capitalize(text: string) {
  return text.charAt(0).toUpperCase() + text.slice(1);
}

export const pluralize: (count: number, noun: string, suffix?: string) => string = (
  count = 0,
  noun,
  suffix = 's',
) => `${noun}${count !== 1 ? suffix : ''}`;

export const sortListItemAsc = (a: Option, b: Option) =>
  (a?.children as string)?.localeCompare(b?.children as string);
